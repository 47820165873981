import React from "react";
import { useEffect, useState } from "react";
import auth from "../services/auth";
import styled from "styled-components";
import { NavLink, useHistory } from "react-router-dom";

function Navbar({ handleUser }) {
  const [user, setUser] = useState();

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    const user = await auth.getCurrentUser();
    if (user) setUser(user);
    return () => handleUser(user);
  }

  return (
    <div>
      <Container className="Navbar">
        <Link to="/">
          <Logo src={"logo11.png"}></Logo>
        </Link>
        <Facebook>
          {!user && (
            <img
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=100063695312543",
                  "_blank"
                )
              }
              src="facebook.png"
            ></img>
          )}
        </Facebook>
        <Instagram>
          {!user && (
            <img
              onClick={() =>
                window.open("https://www.instagram.com/ellllibelli/", "_blank")
              }
              className="instagram"
              src="instagram.png"
            ></img>
          )}
        </Instagram>
      </Container>
    </div>
  );
}

export default Navbar;

const Container = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: transparent;
  h1 {
    top: 60px;
    right: 06px;
    font-size: 12px;
    color: black;
    position: fixed;
    @media screen and (max-width: 1000px) {
      font-size: 20px;
      top: 80px;
    }
  }
`;

const Link = styled(NavLink)`
  :hover {
  }
`;
const Logo = styled.img`
  height: 120px;
  width: 240px;
  display: flex;
  justify-self: left;
  margin-top: 10px;
  opacity: 1;
  margin-right: 1500px;
  position: fixed;
  z-index: 99;

  @media screen and (max-width: 1000px) {
    height: 250px;
    width: 420px;
  }
`;

const Facebook = styled.div`
  img {
    top: 3px;
    right: 80px;
    height: 50px;
    margin-top: 5px;
    position: fixed;
    border-radius: 12px;
    z-index: 99;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
      height: 100px;
      right: 150px;
    }
  }
`;
const Instagram = styled.div`
  img {
    top: 0px;
    right: 20px;
    height: 55px;
    margin-top: 5px;
    position: fixed;
    border-radius: 2px;
    z-index: 99;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
      height: 122px;
      top: -5px;
    }
  }
`;
