import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import styled from "styled-components";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";

const customMarker = new L.Icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.flyTo(center, zoom, { animate: true, duration: 1.5 });
  }, [center, zoom, map]);
  return null;
};

function Maps({ testY, testX }) {
  const [center, setCenter] = useState([testX, testY]);

  useEffect(() => {
    setCenter([testX, testY]);
  }, [testX, testY]);

  return (
    <StyledMaps>
      <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
        <ChangeView center={center} zoom={13} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={center} icon={customMarker}>
          <Popup>You have been here</Popup>
        </Marker>
      </MapContainer>
    </StyledMaps>
  );
}

export default Maps;

const StyledMaps = styled.div`
  height: 150%;
  width: 100%;
  margin: 5px;
  .leaflet-container {
    height: 98.5%;
    width: 98.5%;
    z-index: 98;
  }
`;
