import React from "react";
import styled from "styled-components";
import { NavLink, Redirect, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { map } from "leaflet";
import AnimatedPage from "../AnimatedPage";


function Bokningar() {
  let history = useNavigate();
  const [order, setOrder] = useState([]);
  const [stats, setStats] = useState([]);
  const [isDone, setisDone] = useState(false);
  const year = stats.map((s) => s.date.slice(0, 4));
  const date = [...new Set(year)].sort().reverse();
  const [isMobile, setIsMobile] = useState("");
  const [timer, setTimer] = useState("");
  const [user, setUser] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrder();
    getStats();
    handleResize();
    handleTimer();
  }, []);

  let element = document.querySelector(".bosse");

  if (element) {
    element.remove();
  }

  useEffect(() => {
    getOrder();
  }, [isDone]);

  const onLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  function handleTimer() {
    const interval = setInterval(() => {
      return setTimer(true);
    }, 2100);
  }

  function handleResize() {
    if (window.screen.width < 1200) {
      return setIsMobile(true);
    } else {
      return setIsMobile(false);
    }
  }

  async function getOrder() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/bokning"
    );
    return setOrder(data);
  }
  async function getStats() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/stats"
    );
    return setStats(data);
  }

  const handleDone = async (o) => {
    setisDone(!isDone);

    if (o.isDone === false) {
      await axios.put(
        "https://ellinornielsen-backend.onrender.com/api/bokning/" + o._id,
        {
          förnamn: o.förnamn,
          efternamn: o.efternamn,
          epost: o.epost,
          adress: o.adress,
          telefon: o.telefon,
          datum: o.datum,
          tidFrån: o.tidFrån,
          tidTill: o.tidTill,
          isDone: true,
        }
      );
    }
    if (o.isDone === true) {
      await axios.put(
        "https://ellinornielsen-backend.onrender.com/api/bokning/" + o._id,
        {
          firstName: o.firstName,
          efternamn: o.efternamn,
          epost: o.epost,
          adress: o.adress,
          telefon: o.telefon,
          datum: o.datum,
          tidFrån: o.tidFrån,
          tidTill: o.tidTill,
          isDone: false,
        }
      );
    }
    getOrder();
  };

  async function handleDelete(id, order, i) {
    if (order[i].isDone === true) {
      const stats = await axios.post(
        "https://ellinornielsen-backend.onrender.com/api/stats",
        {
          förnamn: order[i].förnamn,
          surName: order[i].efternamn,
          epost: order[i].epost,
          adress: order[i].adress,
          telefon: order[i].telefon,
          datum: order[i].datum,
          tidFrån: order[i].tidFrån,
          tidTill: order[i].tidTill,
        }
      );
      getStats();
    }
    const delOrder = await axios.delete(
      "https://ellinornielsen-backend.onrender.com/api/bokning/" + id
    );
    let orders = order.filter((o) => o._id !== id);
    setOrder(orders);
    return stats;
  }

  return (
    <Total>
      <Logout onClick={onLogout}>Logga ut</Logout>
      {!timer && (
        <Jumping>
          <div className="bosse animate__animated animate__fadeOut">
            <h3 className="text"> Bokningar</h3>
          </div>
        </Jumping>
      )}
      <div>
        <Container>
          <div className="mainView">
            <table className="stats">
              <thead>
                <h3 style={{ maxWidth: 150 }} className="Order">
                  Order statestik:
                </h3>

                <tr className="trThStats">
                  <th style={{ maxWidth: 300 }} className="thStats">
                    År:
                  </th>
                  <th style={{ maxWidth: 150 }}>Antal: </th>
                </tr>
              </thead>
              {date.map((o) => {
                return (
                  <tbody key={o}>
                    <tr className="trTdStats">
                      <td className="tdStats">{o}</td>

                      <td>
                        {
                          year.filter(function (value) {
                            return value === o;
                          }).length
                        }
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
          <Social>
            <div className="content">
              <Link to="/om" className="link">
                <div className="card">
                  <div className="icon"></div>
                  <p className="title">Om mig</p>
                  <p className="text">Klicka här för att läsa mer om mig.</p>
                </div>
              </Link>

              <Link to="/spelningar" className="link">
                <div className="card">
                  <div className="icon"></div>
                  <p className="title">Lyssna</p>
                  <p className="text">
                    Här kan du ta en del av mina tidigare spelningar
                  </p>
                </div>
              </Link>
            </div>
          </Social>
          <div className="secondView">
            <table>
              <thead>
                <tr className="trTh">
                  <th>Namn</th>
                  <th>Email</th>
                  <th>Adress</th>
                  <th>Telefon</th>
                  <th>Date</th>
                  <th>Tid</th>
                  <th>Typ</th>
                </tr>
              </thead>
              {order.map((o, i) => {
                return (
                  <tbody key={order._id || "button" + o._id}>
                    <tr className="trTd">
                      <td>
                        {o.förnamn}
                        {o.efternamn}
                      </td>
                      <td>{o.epost}</td>
                      <td>{o.adress}</td>
                      <td>{o.telefon}</td>
                      <td>{o.datum}</td>
                      <td>{o.tidFrån}</td>
                      <td>{o.tidTill}</td>

                      {o.isDone && (
                        <td>
                          <button
                            className="buttonDone"
                            onClick={() => handleDone(o)}
                          >
                            ✓
                          </button>
                        </td>
                      )}
                      {!o.isDone && (
                        <td>
                          <button
                            className="buttonPending"
                            onClick={() => handleDone(o)}
                          >
                            Pågående
                          </button>
                        </td>
                      )}
                      <td style={{ maxWidth: 0 }}>
                        <button
                          className="buttonDel"
                          onClick={() => handleDelete(o._id, order, i)}
                        >
                          X
                        </button>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </Container>
      </div>
    </Total>
  );
}

export default Bokningar;

const Total = styled.div``;
const Jumping = styled.div`
  position: relative;
  display: grid;
  place-content: center;

  .text {
    color: white;
    margin-top: 60vh;
    margin-left: 50%;
    font-family: "Brush Script MT, Brush Script Std, cursive";
    font-style: italic;
    @media screen and (max-width: 1000px) {
      margin-left: 30vw;
      font-size: 100px;
      margin-top: 40vh;
    }
  }
  .bosse {
    background-color: black;
    height: 3000px;
    width: 3000px;
    margin-top: -400px;
    animation-duration: 3s;
    @media screen and (max-width: 1000px) {
      height: 200vh;
      width: 120vw;
    }
  }
`;
const Container = styled.div`
  .mainView {
    z-index: 1;
    padding-left: 15px;
    margin-left: 1vw;
    height: 520px;
    width: 220px;
    border-radius: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    background: rgba(162, 97, 23, 0.59);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(162, 97, 23, 0.98);
    position: absolute;
    margin-top: -20vh;

    @media screen and (max-width: 1000px) {
      margin-top: 90%;
      width: 400px;
    }

    .tbody {
      width: 20px;
    }

    .table {
    }

    .Order {
      @media screen and (max-width: 1000px) {
        font-size: 55px;
        max-width: 2500px;
      }
    }

    .trThStats {
      display: grid;
      width: 20px;
      grid-template-columns: 16% 1fr;
      width: 200px;
      margin-left: -400px;
      margin-right: 0px;
      @media screen and (max-width: 1000px) {
        font-size: 32px;
        width: 1500px;
        grid-template-columns: 8% auto;
      }
    }
    .trTdStats {
      display: grid;
      grid-template-columns: 43% 1fr;
      width: 200px;
      margin-left: -400px;
      border-bottom: 2px solid black;
      @media screen and (max-width: 1000px) {
        font-size: 32px;
        width: 500px;
      }
    }

    .tdStats {
      margin-top: 20px;
      width: 30px;
      @media screen and (max-width: 1000px) {
        font-size: 32px;
      }
    }
    .tableStats {
      height: 20px;
      margin-left: 0px;
      @media screen and (max-width: 1000px) {
        font-size: 32px;
      }
    }

    .theadStats {
      color: black;
    }

    .tbodyStats {
      border-bottom: 2px solid black;
      color: black;
    }
    .stats {
      margin-left: 400px;
      position: relative;
    }
  }

  .secondView {
    border-radius: 12px;
    padding: 10px;
    z-index: 1;
    height: 500px;
    overflow: auto;
    position: absolute;
    background: rgba(162, 97, 23, 0.59);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(162, 97, 23, 0.98);
    margin-left: 20vw;
    margin-top: -20vh;

    @media screen and (max-width: 1000px) {
      margin-top: -5%;
      height: 1000px;
      margin-left: 0vw;
    }
  }

  h3 {
    margin-left: -400px;
  }

  .trTh {
    display: grid;
    grid-template-columns: 14% 16% 15% 16% 12% 13% 5% 10% 10%;
    width: 1200px;
    margin-left: -60px;
    margin-right: 100px;
    @media screen and (max-width: 1000px) {
      grid-template-columns: 16% 16% 15% 16% 12% 13% 5% 10% 10%;
      font-size: 22px;
    }
  }
  .trTd {
    display: grid;
    grid-template-columns: 14% 16% 16% 14% 14% 9% 10% 10% 10%;
    width: 1200px;
    border-bottom: 2px solid black;
    @media screen and (max-width: 1000px) {
      grid-template-columns: 17% 14% 16% 14% 14% 5% 10% 10% 10%;
      word-wrap: break-word;
      font-size: 32px;
    }
  }

  td {
    margin-top: 20px;
    @media screen and (max-width: 1000px) {
      height: 120px;
    }
  }

  thead {
    width: 100%;
    color: black;
  }
  tbody {
    width: 100%;
    border-bottom: 2px solid black;
    color: black;
    background-color: #ffffff94;
  }

  .buttonDel {
    margin-left: -30px;
    background-color: red;
    position: relative;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
      height: 60px;
      width: 60px;
    }
  }
  .buttonPending {
    margin-left: -27px;
    background-color: gray;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    position: relative;
    @media screen and (max-width: 1000px) {
      width: 100px;
      height: 50px;
      font-size: 18px;
    }
  }
  .buttonDone {
    margin-left: -1px;
    background-color: green;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    color: white;

    @media screen and (max-width: 1000px) {
      height: 60px;
      width: 60px;
    }
  }
`;

const Social = styled.div`
  @media screen and (max-width: 1000px) {
    zoom: 180%;
  }
  z-index: 99;
  .link {
    text-decoration: none;
    position: relative;
    max-width: 200px;
    margin-left: 20px;
  }
  text-decoration: none;
  body {
    width: 100vw;
    background-color: #1d1d1d;
    margin: 0;
    font-family: helvetica;
  }

  .about {
    $cubic: cubic-bezier(0.64, 0.01, 0.07, 1.65);
    $transition: 0.6s $cubic;
    $size: 40px;
    position: fixed;
    z-index: 99;
    bottom: 10px;
    right: 10px;
    width: $size;
    height: $size;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 0.2s ease;

    .bg_links {
      width: $size;
      height: $size;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(#fff, 0.2);
      border-radius: 100%;
      backdrop-filter: blur(5px);
      position: absolute;
    }

    .logo {
      width: $size;
      height: $size;
      z-index: 99;
      background-image: url(https://rafaelalucas91.github.io/assets/codepen/logo_white.svg);
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 10px 7px;
      opacity: 0.9;
      transition: all 1s 0.2s ease;
      bottom: 0;
      right: 0;
    }

    .social {
      opacity: 0;
      right: 0;
      bottom: 0;

      .icon {
        width: 100%;
        height: 100%;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        display: flex;
        transition: all 0.2s ease, background-color 0.4s ease;
        opacity: 0;
        border-radius: 100%;
      }

      &.portfolio {
        transition: all 0.8s ease;

        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/link.svg);
        }
      }

      &.dribbble {
        transition: all 0.3s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/dribbble.svg);
        }
      }

      &.linkedin {
        transition: all 0.8s ease;
        .icon {
          background-image: url(https://rafaelalucas91.github.io/assets/codepen/linkedin.svg);
        }
      }
    }

    &:hover {
      width: 105px;
      height: 105px;
      transition: all $transition;

      .logo {
        opacity: 1;
        transition: all 0.6s ease;
      }

      .social {
        opacity: 1;

        .icon {
          opacity: 0.9;
        }

        &:hover {
          background-size: 28px;
          .icon {
            background-size: 65%;
            opacity: 1;
          }
        }

        &.portfolio {
          right: 0;
          bottom: calc(100% - 40px);
          transition: all 0.3s 0s $cubic;

          .icon {
            &:hover {
              background-color: #698fb7;
            }
          }
        }

        &.dribbble {
          bottom: 45%;
          right: 45%;
          transition: all 0.3s 0.15s $cubic;
          .icon {
            &:hover {
              background-color: #ea4c89;
            }
          }
        }

        &.linkedin {
          bottom: 0;
          right: calc(100% - 40px);
          transition: all 0.3s 0.25s $cubic;
          .icon {
            &:hover {
              background-color: #0077b5;
            }
          }
        }
      }
    }
  }

  .wrapper {
    width: 100vw;
    margin: 0 auto;
    height: 400px;
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 767px) {
    .wrapper {
      height: 700px;
    }
  }

  .content {
    width: 100vw;
    height: 30%;
    padding: 0 4%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    bottom: -5%;
    position: fixed;
    @media screen and (max-width: 1000px) {
      left: -26%;
    }
  }

  .card {
    width: 50%;
    max-width: 300px;
    min-width: 200px;
    height: 150px;
    background-color: rgba(193, 150, 86, 0.781);
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 12px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .mic {
      margin-left: -200px;
      margin-top: -90px;
      height: 80px;
      width: 80px;
      position: relative;
    }

    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
      margin-top: -120px;
    }
  }

  .icon {
  }

  .material-icons.md-18 {
    font-size: 12px;
  }
  .material-icons.md-24 {
    font-size: 12px;
  }
  .material-icons.md-36 {
    font-size: 28px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }

  .card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: -40px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 20px;
  }

  .card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
  }

  .card:hover {
    height: 270px;
  }

  .card:hover .info {
    height: 90%;
  }
  .card:hover .info {
    height: 90%;
  }

  .card:hover .text {
    opacity: 1;
    max-height: 40px;
    margin-top: 0px;
    font-size: 15px;
  }

  .card:hover .icon {
    background-position: -120px;
    margin-top: -40px;
    height: 150px;
  }

  .card:hover .title {
    margin-top: -200px;
    margin-bottom: 60px;
  }

  .card:hover .icon i {
    background: linear-gradient(90deg, #ff7e7e, #ff4848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
  }
`;

const Logout = styled.button`
  width: 150px;
  height: 40px;
  font-size: 25px;
  border-radius: 4px;
  border-left: 2px solid black;
  background-color: rgba(162, 97, 23, 1);
  color: white;
  font-family: "helvetica";
  position: absolute;
  right: 10px;
  top: 10px;

  @media screen and (max-width: 1000px) {
    width: 220px;
    height: 60px;
    right: -160px;
  }
`;