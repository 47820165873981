import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  BrowserRouter,
  Redirect,
  Route,
  Routes,
  Switch,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Body from "./components/Body";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Bokning from "./components/Bokning";
import Bokningar from "./components/Bokningar";
import "./App.css";
import HomePage from "./components/HomePage";
import { AnimatePresence } from "framer-motion";
import OmPage from "./components/omPage";
import axios from "axios";

function App() {
  const [user, setUser] = useState([]);
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [pictureList, setpictureList] = useState([]);

  function handleUser(user) {
    return setUser(user);
  }
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    getList();
    getList2();
    handleUser();
    getFolder();
  }, []);

  async function getFolder() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/uploads"
    );
    const Picture = data.map((m) => {
      return m;
    });
    return setpictureList(Picture);
  }
  async function getList() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/order"
    );

    return setItems(data[0].image);
  }
  async function getList2() {
    const { data } = await axios.get(
      "https://ellinornielsen-backend.onrender.com/api/ordersecond"
    );

    return setItems2(data[0].image);
  }

  const updateItems = (newItems) => {
    setItems([newItems]);
  };

  useEffect(() => {}, [items]); // Runs whenever `items` updates

  function getRootName() {
    switch (location.pathname) {
      case "/brollop":
        return "brollop";
      case "/begravning":
        return "begravning";
      case "/dop":
        return "dop";
      case "/ovrigt":
        return "ovrigt";
      default:
        return "home";
    }
  }

  useEffect(() => {
    const root = document.documentElement;
    root.className = ""; // Reset any previous classes
    root.classList.add(getRootName());
  }, [location.pathname]);

  return (
    <AnimatePresence mode={"sync"}>
      <Container className="App">
        <StyledNav>
          <Navbar actuallUser={user} className="Navbar" />
        </StyledNav>
        <Routes key={location.pathname} location={location}>
          <Route path={"/login"} element={<Login />} />
          <Route
            className="bokning"
            path={"/bokning"}
            element={<Bokning className={"denna"} />}
          ></Route>
          <Route
            path={"/bokningar"}
            element={<Bokningar className={"denna"} />}
          ></Route>
          <Route
            path={"/spelningar"}
            element={
              <Body
                itemsProp={items}
                items2Prop={items2}
                updateItems={updateItems}
                className="bosse"
              />
            }
          />
          <Route
            className="homePage"
            path={"/"}
            element={<HomePage handleUser={handleUser} />}
          ></Route>
          <Route className="omPage" path={"/om"} element={<OmPage />} />
        </Routes>
      </Container>
    </AnimatePresence>
  );
}

export default App;

const Container = styled.div`
  margin: 0px;
  height: 100%;
  width: 100%;
  zoom: 90%;
`;

const StyledNav = styled.div`
  margin-bottom: 150px;
`;