import React from "react";
import styled from "styled-components";
import axios from "axios";
import { useState } from "react";
import http from "../services/http";
import auth from "../services/auth";
import { Redirect, useHistory } from "react-router-dom";
import { useEffect } from "react";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    formData[name] = value;
    setFormData({ ...formData });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await auth.login(formData);
      const currentUser = await auth.getCurrentUser();
      return (window.location = "/");
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const elts = {
      text1: document.getElementById("text1"),
      text2: document.getElementById("text2"),
    };

    const texts = ["Made with Love", "By", "Timmy Hanke"];

    const morphTime = 1;
    const cooldownTime = 0.25;

    let textIndex = texts.length - 1;
    let time = new Date();
    let morph = 0;
    let cooldown = cooldownTime;

    elts.text1.textContent = texts[textIndex % texts.length];
    elts.text2.textContent = texts[(textIndex + 1) % texts.length];

    function doMorph() {
      morph -= cooldown;
      cooldown = 0;

      let fraction = morph / morphTime;

      if (fraction > 1) {
        cooldown = cooldownTime;
        fraction = 1;
      }

      setMorph(fraction);
    }

    function setMorph(fraction) {
      elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      fraction = 1 - fraction;
      elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
      elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

      elts.text1.textContent = texts[textIndex % texts.length];
      elts.text2.textContent = texts[(textIndex + 1) % texts.length];
    }

    function doCooldown() {
      morph = 0;

      elts.text2.style.filter = "";
      elts.text2.style.opacity = "100%";

      elts.text1.style.filter = "";
      elts.text1.style.opacity = "0%";
    }

    function animate() {
      requestAnimationFrame(animate);

      let newTime = new Date();
      let shouldIncrementIndex = cooldown > 0;
      let dt = (newTime - time) / 2000;
      time = newTime;

      cooldown -= dt;

      if (cooldown <= 0) {
        if (shouldIncrementIndex) {
          textIndex++;
        }

        doMorph();
      } else {
        doCooldown();
      }
    }

    animate();
  }, []);

  return (
    <Container>
      <h1>Ellinor Nielsen Login</h1>

      <div className="changingText">
        <div id="container">
          <span id="text1"></span>
          <span id="text2"></span>
        </div>

        <svg id="filters">
          <defs>
            <filter id="threshold">
              <feColorMatrix
                in="SourceGraphic"
                type="matrix"
                values="1 0 0 0 0
                  0 1 0 0 0
                  0 0 1 0 0
                  0 0 0 255 -140"
              />
            </filter>
          </defs>
        </svg>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <input
            label={"Email"}
            name={"email"}
            type={"email"}
            placeholder={"Email"}
            onChange={handleChange}
            value={formData.email}
          />
        </div>
        <div>
          <input
            label={"Password"}
            name={"password"}
            type={"password"}
            placeholder={"Password"}
            onChange={handleChange}
            value={formData.password}
          />
        </div>
        <button onClick={onsubmit}>Login</button>
      </form>
    </Container>
  );
}

export default Login;

const Container = styled.div`
  height: 50vh;
  width: 40vw;
  background: rgba(162, 97, 23, 0.59);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  border: 1px solid rgba(162, 97, 23, 0.98);
  position: absolute;
  z-index: 999;
  left: 40vw;
  top: 20vh;
  opacity: 0.8;
  display: grid;
  place-content: center;
  @media screen and (max-width: 1000px) {
    margin-left: -20%;
    width: 70%;
    height: 30lvh;
  }

  h1 {
    @media screen and (max-width: 1000px) {
      place-self: center;
      font-size: 42px;
    }
  }

  .changingText {
    @import url("https://fonts.googleapis.com/css?family=Raleway:900&display=swap");

    body {
      margin: 0px;
    }
    margin-left: -30vw;
    margin-top: 50vh;
    position: absolute;

    #container {
      position: absolute;
      margin: auto;
      width: 100vw;
      height: 20pt;
      top: 0;
      bottom: 0;

      filter: url(#threshold) blur(0.6px);
    }

    #text1,
    #text2 {
      position: absolute;
      width: 100%;
      display: inline-block;
      font-family: "Raleway", sans-serif;
      font-size: 40pt;
      text-align: center;
      user-select: none;
      @media screen and (max-width: 1000px) {
        margin-left: 20%;
      }
    }
  }

  input {
    height: 40px;
    width: 300px;
    margin: 20px;
    border-radius: 5px;
    font-size: 24px;
    border: 2px solid white;
    box-shadow: 0 0 12px white;
    font-style: italic;
    @media screen and (max-width: 1000px) {
      height: 60px;
      width: 600px;
    }
  }

  button {
    height: 40px;
    width: 200px;
    margin-left: 70px;
    border: 2px solid white;
    border-radius: 12px;
    box-shadow: 0 0 12px black;
    @media screen and (max-width: 1000px) {
      zoom: 200%;
    }
  }

  h1 {
    font-family: fantasy papyrus;
  }
`;
